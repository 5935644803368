import {createStore, applyMiddleware} from 'redux'
import logger from 'redux-logger'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'
import {questionReducer} from './questions/question.reducer'

const middlewares = [thunk]
if (process.env.NODE_ENV === "development"){
    middlewares.push(logger)
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares))
// export const persistor = persistStore(store)

// export default {store, persistor}
export default {store}
