import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getingUserPointAction,
  pontsClear,
  lockWinner,
  setLockWinnerSuccess,
} from "../../redux/userPoints/user.points.action";
import { Table } from "../../containers/Table/user.points.table";
import {
  pointListSelector,
  pointLoadingSelector,
} from "../../redux/userPoints/user.points.selector";
import { Alert } from "../../components/UI/Alert.component";
import { Spinner } from "../../components/UI/Spinner.component";
import { priceListSelector } from "../../redux/prizes/prizes.selector";
import { fetchPrizeList } from "../../redux/prizes/prizes.action";

export function Withdraw() {
  const [limit, setLimit] = useState("");
  const [winnerLimit, setwinnerLimit] = useState("");
  const [prize, setPrize] = useState("");
  const [month, setmonth] = useState();
  const [fromMonth, setfromMonth] = useState("");
  const [toMonth, settoMonth] = useState("");
  const [fromDate, setfromDate] = useState();
  const [toDate, settoDate] = useState();
  const [winnerList, setWinnerList] = useState([]);
  const [drawType, setdrawType] = useState();
  const Dispatch = useDispatch();
  const TableList = useSelector((state) => pointListSelector(state));
  //   const Loading = useSelector((state) => pointLoadingSelector(state));
  const priceList = useSelector((state) => priceListSelector(state));
  const [monthError, setmonthError] = useState()
  const { error, lockWinnerLoading, lockWinnerSuccess, loading } = useSelector(
    (state) => state.point
  );

  useEffect(() => {
    Dispatch(fetchPrizeList());
  }, []);

  useEffect(() => {
    // console.log(prize);
    setfromMonth()
    settoMonth()
    setfromDate()
    settoDate()
    setdrawType()
  }, [prize]);

  const topPonters = (e) => {
    e.preventDefault();

    let data = {
      limit,
      prize,
      // to: toDate,
      drawType,
    };

    if(drawType !== 'jackpot'){
        if (fromDate) {
          data.from = fromDate;
        }
        if (toDate) {
          data.to = toDate;
        }
    }else{
      if (fromMonth) {
        // setfromMonth(fromMonth + '-01')
        data.from = fromMonth + '-01';
      }
      if (toMonth) {
        let month = toMonth.slice(-2)
        console.log('MONTH',month);
        console.log('MONTH: ' ,toString(month));
        let months1 = ['01','03','05','07','08','10','12']
        // let months = [01,03,05,07,08,10,12]
        // months1.every(month => month === )
        
        if(months1.some((mnth)=> mnth == month)){
           data.to = toMonth + '-31';
        }else if(month == 2){
          data.to = toMonth + '-28'
        }else{
          data.to = toMonth + '-30';
        }
    // }
   
  }

    }
    
    // if (toMonth) {
   
   

    if(drawType =='jackpot'){
      var date1 = new Date(data.from);
    var date2 = new Date(data.to);
    var diffMonths = date2.getMonth() - date1.getMonth(); 
    // alert(diffMonths)
    if(diffMonths != 2 && diffMonths !== -10 && diffMonths !== -9){
      setmonthError('Wiiner can only be withdrawn Quartely!!')
      // alert('SOrrry')
    }else{
      Dispatch(getingUserPointAction(data));
    }

    }else{
      Dispatch(getingUserPointAction(data));
    }

    

  
    

   
    setWinnerList([]);

 
  };
  console.log("Month", month);
  const withdrawRandom = () => {
    let WinnerArray = winnerList;
    for (let i = 0; i < winnerLimit; i++) {
      const array = TableList[Math.floor(Math.random() * TableList.length)];
      WinnerArray.push(array);
      setWinnerList(WinnerArray);
    }
    console.log(winnerList);
    if (winnerList.length == winnerLimit) {
      let data = {
        prize: prize,
        users: winnerList,
      };
      // Dispatch(withdrawAction(data))
    }
  };

  const winnerLocking = () => {
    let data = {
      winners: TableList,
      // from: fromDate,
      // to: toDate,
      prize,
    };

    if (toMonth) {
      let month = toMonth.slice(-2)
      console.log('MONTH',month);
      console.log('MONTH: ' ,toString(month));
      let months1 = ['01','03','05','07','08','10','12']
      // let months = [01,03,05,07,08,10,12]
      // months1.every(month => month === )
      
      if(months1.some((mnth)=> mnth == month)){
         data.to = toMonth + '-31';
      }else if(month == 2){
        data.to = toMonth + '-28'
      }else{
        data.to = toMonth + '-30';
      }
    }

    if (fromMonth) {
      data.from = fromMonth + '-01';
    }
    
    if(drawType !== 'jackpot'){
      if (fromDate) {
      data.from = fromDate;
    }
    if (toDate) {
      data.to = toDate;
      }
    }

    console.log('WINNER LOCK DATA',data);

    Dispatch(lockWinner(data));
  };

  useEffect(() => {
    console.log("winnerList", winnerList);
  }, [winnerList]);

  useEffect(() => {
    if (prize == "1000000") {
      setdrawType("jackpot");
    } else {
      setdrawType("");
    }
  }, [prize]);

  // useEffect(() => {
    
  // }, [toMonth,fromMonth]);

  return (
    <div className="withdraw-page">
      <div className="container">
        <h5 className="text-center p-3 text-info h4">Winner Draw</h5>
        <div className="row">
          <form
            onSubmit={topPonters}
            // className="row justify-content-center align-items-end pb-2"
          >
            <div className="row justify-content-center align-items-end pb-2">
              <div className="col text-center">
                <p className="text-info h5 mb-4">Select Prize</p>
                {priceList.map((price) => {
                  console.log("Price", price.prize);
                  return (
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value={price.prize}
                        onChange={(e) => setPrize(e.target.value)}
                      ></input>
                      <label class="form-check-label" for="inlineRadio2">
                        {price.prize} F
                      </label>
                    </div>
                  );
                })}

                {/* <div className="text-center"> */}

                {/* </div> */}
              </div>
            </div>
            <div className="row justify-content-center align-items-end mt-2">
              {prize && (
                <>
                  {" "}
                  {prize !== "1000000" && (
                    <>
                      <div className="col-2">
                        <label>From: &nbsp;</label>
                        <input
                          type="date"
                          value={fromDate}
                          className="form-control"
                          onChange={(e) => setfromDate(e.target.value)}
                        ></input>
                      </div>
                      <div className="col-2">
                        <label>To: &nbsp;</label>
                        <input
                          type="date"
                          value={toDate}
                          onChange={(e) => settoDate(e.target.value)}
                          className="form-control"
                        ></input>
                      </div>
                    </>
                  )}
                  {prize == "1000000" && (
                    <>
                      <div className="col-2">
                        <label>From &nbsp;</label>
                        <input
                          type="month"
                          value={fromMonth}
                          onChange={(e) =>{ 
                            console.log('FROM MONTH',fromMonth);
                            setfromMonth(e.target.value)}}
                          className="form-control"
                        ></input>
                      </div>
                      <div className="col-2">
                        <label>To &nbsp;</label>
                        <input
                          type="month"
                          value={toMonth}
                          onChange={(e) => {
                            console.log('TO MONTH',toMonth);
                            settoMonth(e.target.value)
                          }}
                          className="form-control"
                        ></input>
                      </div>
                    </>
                  )}
                  <div className="col-2">
                    <label>No of winners: &nbsp;</label>
                    <select
                      name="limit"
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                      className="form-control"
                      required
                    >
                      <option value="">Select No. of winners</option>
                      <option value="1">1</option>
                      {drawType != "jackpot" && <option value="10">10</option>}
                    </select>
                  </div>
                  <div className="col-2">
                    <button type="submit" className="btn btn-winner">
                      Get Winners
                    </button>
                  </div>
                </>
              )}
            </div>
            {/* <div className="row justify-content-center align-items-end pb-2">
              
            </div> */}
          </form>
        </div>

        {TableList.length !== 0 && (
          <Table tableTitle="" data={TableList} loading={loading} />
        )}
        {loading && (
          <h6 style={{ margin: "20px auto", textAlign: "center" }}>
            Getting Data...
          </h6>
        )}
        {/* TABLE GOES HERE */}
        <div className="lock-button text-center align-items-end">
          {TableList.length !== 0 && (
            <button onClick={winnerLocking} className="btn btn-dark">
              {!lockWinnerLoading ? "Lock" : "Locking..."}
            </button>
          )}
        </div>
      </div>
      <Alert
        title={error}
        isOpen={error}
        close={() => Dispatch(pontsClear())}
      />
       <Alert
        title={monthError}
        isOpen={monthError}
        close={() => setmonthError()}
      />
      {lockWinnerSuccess && (
        <Alert
          title={lockWinnerSuccess}
          isOpen={lockWinnerSuccess}
          close={() => Dispatch(setLockWinnerSuccess(false))}
        />
      )}
    </div>
  );
}
